import isCurrentPractitioner from '@/practitioner/utils/isCurrentPractitioner';
import practitionerViews from '@/practitioner/views';
import {CurrentUserBase} from '@/shared/contexts/UserContext';
import sharedPaths from '@/shared/routing/paths';
import sharedUserViews from '@/shared/views';
import paths from './paths';

const accessGranted = (user: CurrentUserBase) => isCurrentPractitioner(user);
const companyAdminGranted = (user: CurrentUserBase) =>
  isCurrentPractitioner(user) && user.practitionerPermissions.administerCompany;
const practitionerAdminGranted = (user: CurrentUserBase) =>
  isCurrentPractitioner(user) && user.practitionerPermissions.administerPractitioners;
const viewPractitionersGranted = (user: CurrentUserBase) =>
  isCurrentPractitioner(user) && user.practitionerPermissions.viewPractitioners;
const createPaymentOrderGranted = (user: CurrentUserBase) =>
  isCurrentPractitioner(user) && user.practitionerPermissions.createPaymentOrder;
const viewDiagnosticReportGranted = (user: CurrentUserBase) =>
  isCurrentPractitioner(user) && user.practitionerPermissions.viewDiagnosticReports;

export const USER_HOME = {
  accessGranted: accessGranted,
  component: practitionerViews.UserHome,
  path: sharedPaths.USER_HOME
};

export const USER_PROFILE = {
  accessGranted: accessGranted,
  component: practitionerViews.UserProfile,
  path: sharedPaths.USER_PROFILE
};

export const AUDIT_LOG = {
  accessGranted: accessGranted,
  component: practitionerViews.AuditLog,
  path: sharedPaths.AUDIT_LOG
};

export const USER_BACKUP = {
  accessGranted,
  component: sharedUserViews.UserBackup,
  path: sharedPaths.USER_BACKUP
};

export const ACTIVITY = {
  component: practitionerViews.MonthlyActivity,
  path: paths.MONTHLY_ACTIVITY,
  accessGranted: (user: CurrentUserBase) =>
    isCurrentPractitioner(user) && user.isQualifiedPractitioner
};

export const DAILY_ACTIVITY = {
  component: practitionerViews.DailyActivity,
  path: paths.DAILY_ACTIVITY,
  accessGranted
};

export const DIAGNOSTIC_REPORT_REQUEST = {
  accessGranted,
  component: practitionerViews.DiagnosticReportRequest,
  path: paths.DIAGNOSTIC_REPORT_REQUEST
};

export const DIAGNOSTIC_REPORT_SENT = {
  accessGranted,
  component: practitionerViews.DiagnosticReportSent,
  path: paths.DIAGNOSTIC_REPORT_SENT
};

export const QUICK_DIAGNOSTIC_REPORT_REQUEST = {
  accessGranted,
  component: practitionerViews.QuickDiagnosticReportRequest,
  path: paths.QUICK_DIAGNOSTIC_REPORT_REQUEST
};

export const DIAGNOSTIC_REPORT_RESULT = {
  component: practitionerViews.DiagnosticReportResult,
  path: paths.DIAGNOSTIC_REPORT_RESULT,
  accessGranted: viewDiagnosticReportGranted
};

export const DIAGNOSTIC_REPORTS = {
  component: practitionerViews.DiagnosticReports,
  path: paths.DIAGNOSTIC_REPORTS,
  accessGranted: viewDiagnosticReportGranted
};

export const NOTIFICATION_LIST = {
  component: sharedUserViews.NotificationList,
  path: sharedPaths.NOTIFICATION_LIST,
  accessGranted
};

export const PATIENT_DETAIL = {
  component: practitionerViews.PatientDetail,
  path: paths.PATIENT_DETAIL,
  accessGranted: accessGranted
};

export const PATIENT_RECOVER_PASSWORD = {
  component: practitionerViews.PatientRecoverPassword,
  path: paths.PATIENT_RECOVER_PASSWORD,
  accessGranted: accessGranted
};

export const PATIENT_DELETE = {
  component: practitionerViews.PatientDelete,
  path: paths.PATIENT_DELETE,
  accessGranted: accessGranted
};

export const PATIENT_LIST = {
  component: practitionerViews.PatientList,
  path: paths.PATIENT_LIST,
  accessGranted: accessGranted
};

export const PATIENT_CREATE = {
  component: practitionerViews.PatientCreate,
  path: paths.PATIENT_CREATE,
  accessGranted: accessGranted
};

export const PATIENT_TEMPORARY_IMAGES_UPLOAD = {
  component: practitionerViews.PatientTemporaryImagesUpload,
  path: paths.PATIENT_TEMPORARY_IMAGES_UPLOAD,
  accessGranted: accessGranted
};

export const COMPANY_DETAIL = {
  component: practitionerViews.CompanyDetail,
  path: paths.COMPANY_DETAIL,
  accessGranted: companyAdminGranted
};

export const PATHOLOGY_CUSTOMIZATION_LIST = {
  component: practitionerViews.PathologyCustomizationList,
  path: paths.PATHOLOGY_CUSTOMIZATION_LIST,
  accessGranted: companyAdminGranted
};

export const PATHOLOGY_CUSTOMIZATION_CREATE = {
  component: practitionerViews.PathologyCustomizationCreate,
  path: paths.PATHOLOGY_CUSTOMIZATION_CREATE,
  accessGranted: companyAdminGranted
};

export const PATHOLOGY_OVERRIDE_DELETE = {
  component: practitionerViews.PathologyOverrideDelete,
  path: paths.PATHOLOGY_OVERRIDE_DELETE,
  accessGranted: companyAdminGranted
};

export const PATHOLOGY_CUSTOMIZATION_EDIT = {
  component: practitionerViews.PathologyCustomizationEdit,
  path: paths.PATHOLOGY_CUSTOMIZATION_EDIT,
  accessGranted: companyAdminGranted
};

export const COMPANY_UPDATE_PAYMENT_METHOD = {
  component: practitionerViews.UpdatePaymentMethod,
  path: paths.COMPANY_UPDATE_PAYMENT_METHOD,
  accessGranted: companyAdminGranted
};

export const COMPANY_METRICS = {
  component: practitionerViews.CompanyMetrics,
  path: paths.COMPANY_METRICS,
  accessGranted: practitionerAdminGranted
};

export const PATIENT_PAYMENT_ORDER_LIST = {
  component: practitionerViews.PaymentOrderList,
  path: paths.PATIENT_PAYMENT_ORDER_LIST,
  accessGranted: practitionerAdminGranted
};

export const PATIENT_PAYMENT_ORDER_CREATE = {
  component: practitionerViews.PaymentOrderCreate,
  path: paths.PATIENT_PAYMENT_ORDER_CREATE,
  accessGranted: createPaymentOrderGranted
};

export const PRACTICIONER_LIST = {
  component: practitionerViews.PractitionerList,
  path: paths.PRACTICIONER_LIST,
  accessGranted: viewPractitionersGranted
};

export const PRACTICIONER_EDIT = {
  component: practitionerViews.PractitionerEdit,
  path: paths.PRACTICIONER_EDIT,
  accessGranted: practitionerAdminGranted
};

export const PRACTICIONER_CREATE = {
  component: practitionerViews.PractitionerCreate,
  path: paths.PRACTICIONER_CREATE,
  accessGranted: practitionerAdminGranted
};

export const PRACTICIONER_DELETE = {
  component: practitionerViews.PractitionerDelete,
  path: paths.PRACTICIONER_DELETE,
  accessGranted: practitionerAdminGranted
};

export const PRACTICIONER_RECOVER_PASSWORD = {
  component: practitionerViews.PractitionerRecoverPassword,
  path: paths.PRACTICIONER_RECOVER_PASSWORD,
  accessGranted: practitionerAdminGranted
};

export const WORK_LIST = {
  component: practitionerViews.WorkList,
  path: paths.WORK_LIST,
  accessGranted: (user: CurrentUserBase) => isCurrentPractitioner(user) && !user.isAdmissions
};

export const LOGOUT = {
  accessGranted,
  component: sharedUserViews.Logout,
  path: sharedPaths.LOGOUT,
  title: null
};

export const CONTACT_FORM = {
  accessGranted,
  component: sharedUserViews.ContactForm,
  path: sharedPaths.CONTACT_FORM
};

export const EVENT_DETAIL = {
  component: practitionerViews.EventDetail,
  path: sharedPaths.EVENT,
  accessGranted
};

export const EVENT_CREATE = {
  component: practitionerViews.EventCreate,
  path: paths.EVENT_CREATE,
  accessGranted
};

export const EVENT_EDIT = {
  component: practitionerViews.EventEdit,
  path: paths.EVENT_EDIT,
  accessGranted
};

export const INACTIVE_COMPANY = {
  accessGranted: companyAdminGranted,
  component: practitionerViews.InactiveCompany,
  path: paths.INACTIVE_COMPANY
};

export const MEDICATION_REQUEST_CREATE = {
  component: practitionerViews.MedicationRequestCreate,
  path: paths.MEDICATION_REQUEST_CREATE,
  accessGranted
};

export const MEDICATION_REQUEST_DELETE = {
  component: practitionerViews.MedicationRequestDelete,
  path: paths.MEDICATION_REQUEST_DELETE,
  accessGranted
};

export const MEDICATION_REQUEST_EDIT = {
  component: practitionerViews.MedicationRequestEdit,
  path: paths.MEDICATION_REQUEST_EDIT,
  accessGranted
};

export const MEDICATION_REQUEST_PDF = {
  accessGranted,
  component: sharedUserViews.MedicationRequestPdf,
  path: sharedPaths.MEDICATION_REQUEST_PDF
};

const routes = [
  INACTIVE_COMPANY,
  CONTACT_FORM,
  LOGOUT,
  USER_HOME,
  AUDIT_LOG,
  USER_PROFILE,
  DIAGNOSTIC_REPORT_REQUEST,
  QUICK_DIAGNOSTIC_REPORT_REQUEST,
  WORK_LIST,
  ACTIVITY,
  DAILY_ACTIVITY,
  DIAGNOSTIC_REPORT_RESULT,
  DIAGNOSTIC_REPORT_SENT,
  DIAGNOSTIC_REPORTS,
  PATIENT_CREATE,
  PATIENT_DETAIL,
  PATIENT_DELETE,
  PATIENT_LIST,
  PATIENT_RECOVER_PASSWORD,
  PATIENT_TEMPORARY_IMAGES_UPLOAD,
  NOTIFICATION_LIST,
  PRACTICIONER_CREATE,
  PRACTICIONER_LIST,
  PRACTICIONER_EDIT,
  PRACTICIONER_DELETE,
  PRACTICIONER_RECOVER_PASSWORD,
  COMPANY_UPDATE_PAYMENT_METHOD,
  COMPANY_METRICS,
  COMPANY_DETAIL,
  PATHOLOGY_CUSTOMIZATION_LIST,
  PATHOLOGY_CUSTOMIZATION_CREATE,
  PATHOLOGY_OVERRIDE_DELETE,
  PATHOLOGY_CUSTOMIZATION_EDIT,
  USER_BACKUP,
  EVENT_DETAIL,
  EVENT_CREATE,
  EVENT_EDIT,
  PATIENT_PAYMENT_ORDER_LIST,
  PATIENT_PAYMENT_ORDER_CREATE,
  MEDICATION_REQUEST_PDF,
  MEDICATION_REQUEST_CREATE,
  MEDICATION_REQUEST_DELETE,
  MEDICATION_REQUEST_EDIT
];

export default routes;
