import {ReactElement, ReactNode} from 'react';
import Box from '@mui/material/Box';
import {SxProps, Theme} from '@mui/material/styles';

type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  mb?: number;
  mt?: number;
};

function FlexBox({
  children,
  justifyContent = 'flex-start',
  mb = 0,
  mt = 0,
  flexDirection = 'row',
  sx = []
}: Props): ReactElement {
  return (
    <Box
      sx={[
        {
          justifyContent: justifyContent
        },
        ...(Array.isArray(sx) ? sx : [sx]),
        (theme) => ({
          display: 'flex',
          alignItems: 'center',
          flexDirection: flexDirection ?? 'row',
          justifyContent: justifyContent ?? 'flex-start',
          marginBottom: mb ? theme.spacing(mb) : 0,
          marginTop: mt ? theme.spacing(mt) : 0,
          '& > *': {
            marginRight: justifyContent === 'flex-start' ? theme.spacing(1) : '0',
            marginLeft: justifyContent === 'flex-end' ? theme.spacing(1) : '0'
          }
        })
      ]}
    >
      {children}
    </Box>
  );
}

export default FlexBox;
