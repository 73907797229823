import {ENABLED_LOCALES, Language} from '@legit.health/ui';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import userLocaleLocalStorage from '@/shared/userLocale/userLocaleLocalStorage';

const userLang = navigator.language || Language.English;
const defaultLocale = userLang.substring(0, 2);

const enabledLocales: string[] = ENABLED_LOCALES;
export const locale = enabledLocales.includes(defaultLocale) ? defaultLocale : Language.English;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: userLocaleLocalStorage.get() || locale,
    interpolation: {
      escapeValue: false
    },
    returnNull: false,
    fallbackLng: false,
    ns: [],
    defaultNS: 'shared/common',
    backend: {
      queryStringParams: {v: '1.3.1'},
      loadPath: function (lngs: any, namespaces: any) {
        const [namespace] = namespaces;
        const [first] = lngs;
        if (!first || !namespace) {
          return '';
        }
        if (namespace.startsWith('ui')) {
          return `/ui/${first}/ui.json`;
        }
        return `/locales/${first}/${namespace}.json`;
      }
    }
  });

export default i18n;
