import {forwardRef, ReactNode, useLayoutEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Slide, SlideProps} from '@mui/material';
import Modal from './Modal';

const Transition = forwardRef<HTMLDivElement, SlideProps>(function Transition(
  props: SlideProps,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenModal({
  children,
  isOpened,
  onClose,
  title,
  id
}: {
  children: ReactNode;
  isOpened: boolean;
  onClose: () => void | null;
  title: ReactNode;
  id?: string;
}) {
  useLayoutEffect(function () {
    const body = document.body;

    const observer = new MutationObserver(function () {
      // body.style.touchAction = body.style.overflow === 'hidden' ? 'none' : '';
      const root = document.getElementById('root');
      if (root) {
        root.style.overflow = body.style.overflow;
        root.style.touchAction = body.style.overflow === 'hidden' ? 'none' : '';
      }
    });

    observer.observe(body, {
      attributes: true,
      attributeFilter: ['style']
    });

    return function () {
      if (observer) {
        observer.disconnect();
      }
      const root = document.getElementById('root');
      if (root) {
        root.style.overflow = body.style.overflow;
        root.style.touchAction = '';
      }
    };
  }, []);

  return (
    <Modal
      fullScreen
      Transition={Transition}
      isOpened={isOpened}
      onClose={onClose}
      id={id}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          data-testid="closeModalBtn"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            p: 2
          }}
        >
          {children}
        </Box>
      </>
    </Modal>
  );
}
