import {ReactElement} from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {
  Box,
  Typography,
  Container,
  Grid2 as Grid,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {LOGIN} from '@/anonymous/routing/paths';
import Logo from '@/shared/components/Logo';

function Footer(): ReactElement {
  const {t} = useTranslation(['public/legal']);
  return (
    <Box
      sx={[
        {
          pt: 4,
          px: 3,
          pb: 10
        },
        (theme) => ({
          bgcolor: 'primary.main',
          background: `-webkit-gradient(linear, left top, right bottom, from(${theme.palette.secondary.main}), to(${theme.palette.primary.main}))`,
          color: 'common.white',
          '& .MuiListItemIcon-root': {
            color: 'common.white'
          }
        })
      ]}
    >
      <Container maxWidth="lg">
        <Grid spacing={4} container>
          <Grid
            size={{
              xs: 12,
              sm: 6
            }}
          >
            <Logo variant="h6" withIcon hasGradient={false} />
            <Box
              sx={{
                ml: 1,
                mt: 1
              }}
            >
              <Typography>{t('public/legal:claim')}</Typography>
            </Box>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6
            }}
          >
            <List>
              <ListItemButton divider to={LOGIN} component={Link}>
                <ListItemIcon>
                  <MeetingRoomIcon color="inherit" />
                </ListItemIcon>
                <ListItemText primary={t('public/legal:login')} />
              </ListItemButton>
              <ListItemButton divider component="a" href={t('public/legal:links.latestNews.url')}>
                <ListItemIcon>
                  <NewReleasesIcon color="inherit" />
                </ListItemIcon>
                <ListItemText primary={t('public/legal:links.latestNews.title')} />
              </ListItemButton>
              <ListItemButton component="a" href={t('public/legal:links.blog.url')}>
                <ListItemIcon>
                  <FavoriteIcon color="inherit" />
                </ListItemIcon>
                <ListItemText primary={t('public/legal:links.blog.title')} />
              </ListItemButton>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
