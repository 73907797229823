import {UserId} from '@legit.health/ui';
import * as Sentry from '@sentry/react';
import isProduction from './isProduction';

const sentry = {
  captureException(exception: any): void {
    if (isProduction()) {
      Sentry.captureException(exception);
    }
  },
  setUser(user: {id: UserId; email: string | undefined} | null): void {
    if (isProduction()) {
      Sentry.setUser(user);
    }
  }
};

export default sentry;
